<template>
    <v-container fluid>
        <v-row class="pa-0 mt-16" justify="center">
            <v-col align="center" class="noSpace">
                <v-row align="center" class="noSpace" justify="center">
                    <v-col class="noSpace" cols="12">
                        <!-- FAMILY TITLE -->
                        <p class="screenTitle centuryGothicBold noSpace mt-4">{{ this.familyTitle }}</p>
                    </v-col>
                </v-row>

                <v-row class="noSpace">
                    <v-col class="hidden-sm-and-down" cols="2"></v-col>
                    <v-col align="center" class="familyInfoColumn" lg="8" md="8" sm="12" xl="8" xs="12">
                        <!-- FAMILY DESCRIPTION -->
                        <p class="centuryGothic">
                            {{ this.familyData['description'] }}
                        </p>

                        <!-- FAMILY SUBTITLE -->
                        <p class="screenSubtitle centuryGothicBold noSpace mt-4">{{ this.familySubtitle }}</p>

                        <!-- FAMILY DETAILS TABLE BUTTON -->
                        <v-btn
                            v-if="detailsTableInfo !== null"
                            id="detailsTableButton"
                            :color="familyId"
                            class="centuryGothicBold infoTableButton"
                            dark
                            elevation="6"
                            style="margin-top: 20px;"
                            @click="hideDetailsTable(false)"
                        >¿Cuál producto debo usar?
                        </v-btn>

                        <!-- FAMILY DETAILS TABLE -->
                        <v-container
                            id="detailsTable"
                            :class="familyId"
                            class="infoTable elevation-6 mt-6 hide"
                        >
                            <!-- CLOSE TABLE BUTTON -->
                            <v-btn
                                :color="familyId"
                                class="centuryGothicBold infoTableButton"
                                dark
                                elevation="0"
                                @click="hideDetailsTable(true)"
                            >Cerrar
                            </v-btn>

                            <v-simple-table :class="familyId" dark>
                                <template v-slot:default>
                                    <!-- TABLE COLUMNS' HEADERS ROW -->
                                    <thead>
                                    <tr>
                                        <th class="centuryGothicBold table-title text-uppercase">{{
                                                detailsTableInfo.firstColumnTitle
                                            }}
                                        </th>
                                        <th class="centuryGothicBold table-title">{{
                                                detailsTableInfo.secondColumnTitle
                                            }}
                                        </th>
                                        <th class="centuryGothicBold table-title">{{
                                                detailsTableInfo.thirdColumnTitle
                                            }}
                                        </th>
                                    </tr>
                                    </thead>

                                    <!-- TABLE BODY ROWS -->
                                    <tbody>
                                    <tr v-for="(product, index) in detailsTableInfo.data" :key="index"
                                        class="content-row">
                                        <td class="centuryGothic table-content text-uppercase">{{ product["1"] }}</td>
                                        <td class="centuryGothic table-content">{{ product["2"] }}</td>
                                        <!-- PACKAGE AVAILABILITY ICONS -->
                                        <td class="centuryGothic table-content">
                                            <v-row align="center" class="pa-0" justify="center">
                                                <v-col
                                                    v-if="product['3']['bag'] === true"
                                                    align="center" class="pa-0" cols="4">
                                                    <v-img
                                                        class="comparisonTableIcons"
                                                        src="../../assets/icons/saco.svg"
                                                    ></v-img>
                                                </v-col>
                                                <v-col
                                                    v-if="product['3']['silo'] === true"
                                                    align="center" class="pa-0" cols="4">
                                                    <v-img
                                                        class="comparisonTableIcons"
                                                        src="../../assets/icons/silo.svg"
                                                    ></v-img>
                                                </v-col>
                                            </v-row>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-container>

                        <!-- FAMILY BENEFITS LIST -->
                        <v-row
                            v-if="familyIcon !== null && familyBenefits !== null"
                            align="center"
                            class="pa-0 mt-4"
                        >
                            <!-- BENEFITS LIST -->
                            <v-col class="noSpace" cols="12" md="8">
                                <v-row align="start" class="noSpace" justify="center">
                                    <!-- FAMILY ICON DESKTOP -->
                                    <v-col align="center" class="pa-2" lg="4" md="4" sm="12" xl="4" xs="12">
                                        <v-img
                                            :src="require(`../../assets/icons/${familyIcon}`)"
                                            class="familyIcon"
                                        ></v-img>
                                    </v-col>

                                    <!-- BENEFITS LIST DESKTOP -->
                                    <v-col class="pt-2 pl-4 benefitsList" cols="12" md="8">
                                        <p class="centuryGothicBold" style="font-size: 1.5em; color: #3D3533;">
                                            Beneficios
                                        </p>

                                        <p class="centuryGothic mt-n2">
                                            <span
                                                v-for="(benefit, index) in familyBenefits"
                                                :key="index"
                                            >- {{ benefit }} <br></span>
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <!-- PACKAGES LIST -->
                            <v-col class="packagesContainer" cols="12" md="4">
                                <v-row justify="center" align="center">
                                    <p class="centuryGothicBold" style="font-size: 1.5em; color: #3D3533;">
                                        Presentaciones
                                    </p>
                                </v-row>

                                <v-row justify="center" align="center" class="packagesRow">
                                    <!-- BAG COLUMN -->
                                    <v-col align-self="start" class="" cols="5" v-if="familyData['bag'] === true">
                                        <v-img
                                            src="../../assets/icons/saco.svg"
                                        ></v-img>

                                        <p class="centuryGothic packageLabel" style="text-align: center;">En Saco</p>
                                    </v-col>

                                    <!-- SILO COLUMN -->
                                    <v-col align-self="start" class="" cols="5" v-if="familyData['silos'] === true">
                                        <v-img
                                            src="../../assets/icons/silo.svg"
                                        ></v-img>

                                        <p class="centuryGothic packageLabel" style="text-align: center;">A granel en silo</p>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>

                    </v-col>
                    <v-col class="hidden-sm-and-down" cols="2"></v-col>
                </v-row>

                <!--  PRODUCTS LIST -->
                <v-row align="center" class="productsListRow" justify="start">
                    <!-- PRODUCT CARD -->
                    <v-col v-for="(product, index) in productsList" :key="index" xl="4" lg="4" md="4" cols="12">
                        <Product_Card :product="product"></Product_Card>
                    </v-col>

                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Product_Card from "../../components/cards/Product_Card";
import database from '../../constants/products_database.json';

export default {
    name: "ProductsFamily",
    components: {
        Product_Card,
    },
    async created() {
        const fullPath = this.$router.currentRoute.fullPath.substring(8);
        this.familyData = database['families'][fullPath];
        this.familyId = fullPath;
        this.familyTitle = this.familyData['pageTitle'] ? this.familyData['pageTitle'] : this.familyData['name'];
        this.familySubtitle = this.familyData['pageSubtitle'];
        this.productsList = this.familyData['products'];

        this.familyIcon = this.familyData['icon'];
        this.familyBenefits = this.familyData['benefitsList'];

        let comparisonTableData = this.familyData['comparisonTable'];

        if (comparisonTableData !== null) {
            let parsedTableBody = [];
            let superArray = [];
            let tableBody = comparisonTableData['body'];

            for (let x = 0; x < tableBody.length; x++) {
                let obj = {1: null, 2: null, 3: null};
                const data = tableBody[x];

                obj[1] = data['a'];
                obj[2] = data['b'];
                obj[3] = data['c'];

                parsedTableBody.push(obj);
                superArray.push(parsedTableBody[parsedTableBody.length - 1])
            }

            this.detailsTableInfo = {
                firstColumnTitle: comparisonTableData['headers']['a'],
                secondColumnTitle: comparisonTableData['headers']['b'],
                thirdColumnTitle: comparisonTableData['headers']['c'],
                data: superArray,
            };
        }
    },
    data() {
        return {
            familyId: null,
            familyTitle: null,
            familySubtitle: null,
            familyColor: null,
            familyData: null,
            familyIcon: null,
            familyBenefits: null,
            productsList: null,
            detailsTableInfo: null,
        }
    },
    methods: {
        hideDetailsTable(bool) {
            if (bool === true) {
                document.getElementById("detailsTable").classList.add("hide");
                document.getElementById("detailsTable").classList.remove("show");
                document.getElementById("detailsTableButton").classList.add("show");
                document.getElementById("detailsTableButton").classList.remove("hide");
            } else {
                document.getElementById("detailsTable").classList.add("show");
                document.getElementById("detailsTable").classList.remove("hide");
                document.getElementById("detailsTableButton").classList.add("hide");
                document.getElementById("detailsTableButton").classList.remove("show");
            }
        },
    },
    metaInfo() {
        return {
            title: 'Mezcla Brava | ' + this.familyId.toUpperCase(),
            meta: [
                { vmid: 'description', name: 'description', content: this.familyData['description'] },
                { vmid: 'og:image', name: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'og:image', name: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'twitter:image', name: 'twitter:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'og:url', name: 'og:url', content: 'https://www.mezclabrava.com/' },
                { vmid: 'twitter:url', name: 'twitter:url', content: 'https://www.mezclabrava.com/' },
            ]
        }
    },
}
</script>

<style scoped>
.page-title {
    font-size: 2.5em;
}

.infoTableButton {
    width: 100%;
}

.infoTableButton:hover {
    opacity: 0.9;
}

.infoTable {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.rellena {
    background-color: #231F20;
}

.levanta {
    background-color: #FF883C;
}

.recubre {
    background-color: #0699CE;
}

.coloca {
    background-color: #EE403D;
}

.content-row {
    background-color: #FFF !important;
    opacity: 0.8;
}

.content-row:hover {
    opacity: 0.9;
}

td {
    border-radius: 10px;
}

.v-data-table >>> div > table {
    border-spacing: 0.5rem 0.5rem;
    text-align: center;
}

.table-title {
    color: white !important;
    font-size: 15px !important;
    opacity: 1 !important;
    text-align: center !important;
}

.table-content {
    color: black !important;
    font-size: 15px !important;
    opacity: 1 !important;
}

.show {
    display: block;
}

.hide {
    display: none;
}

.comparisonTableIcons {
    height: 45px !important;
    width: 45px !important;
}

.benefitsList {
    text-align: start !important;
}

.packageLabel {
    font-size: 0.8em;
    margin-top: 10px;
}

.packagesContainer {
    text-align: end !important;
}

.packagesRow {
    justify-items: end !important;
    justify-content: end !important;
    justify-self: end !important;
}

.productsListRow {
    margin: 0 50px !important;
}

@media (max-width: 960px) {
    .familyInfoColumn {
        padding: 0 25px !important;
    }

    .comparisonTableIcons {
        height: 30px !important;
        width: 30px !important;
    }

    .familyIcon {
        max-width: 30%;
    }

    .benefitsList {
        text-align: center !important;
    }

    .packagesContainer {
        text-align: center !important;
    }

    .packagesRow {
        justify-items: center !important;
        justify-content: center !important;
        justify-self: center !important;
    }

    .table-content {
        font-size: 13px !important;
    }

    .productsListRow {
        margin: 0 30px !important;
    }
}
</style>